$textGrey: #808080;
$darkGrey: #333333;
$header: 160px;
$headerMobile: 100px;

$unit: 20px;

$mobile: 767px;
$gridWidth: 1440px;
$bodyWidth: 1440px;
$headerheight: 130px;

$serif: "AmericanTypewriter-Condensed", "American Typewriter", "Courier New",
  "Consolas", serif;

@import "~bulma-scss/utilities/all";
@import "~bulma-scss/base/all";
@import "~animate.css/animate.css";

@import "scss/vars";
@import "scss/mixins";
@import "scss/hamburger";

.wrapper {
  @include mobile {
    padding: 0 20px;
  }
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: $serif;
  color: #000;
}

html {
  font-size: 16px;
  box-sizing: border-box;
  * {
    box-sizing: inherit;
  }
  @include tablet {
    font-size: 24px;
  }
}

body {
  max-width: $bodyWidth;
  margin: 0 auto;
}

h1 {
  font-size: 1rem;
  font-weight: 400;
}

input,
textarea {
  height: 44px;
  border: 2px solid #000;
  text-align: center;
  font-family: $serif;
  font-size: 20px;
  transition: all 0.3s ease;
  &[type="number"] {
    flex: 1;
    max-width: 120px;
  }
  &[type="checkbox"] {
    background: #fff;
  }
  &[type="button"],
  &[type="submit"] {
    flex: 1;
    max-width: 440px;
    background: #fff;
    appearance: none;
    @include mobile {
      padding: 15px;
      min-width: 70vw;
    }
    &:hover {
      background: #000;
      color: #fff;
      cursor: pointer;
    }
  }
  &:focus {
    outline: none;
  }
}

textarea {
  height: auto;
  min-height: 80px;
}

header.desktop {
  @include mobile {
    display: none !important;
  }
  position: fixed;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
  background: rgba(#fff, 0.9);
  ul {
    margin: 0 auto;
    width: 960px;
    height: $headerheight;
    display: flex;
    align-items: center;
    flex: 1;
    list-style: none;
    li {
      flex: 1;
      position: relative;
      text-align: center;
      a {
        text-decoration: none;
        font-size: 17px;
        text-transform: uppercase;
        color: #0c0d0d;
        letter-spacing: 2.28px;
      }
      &.logo {
        height: 100%;
      }
      img.logo {
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -68px;
      }
    }
  }
}

header.mobile {
  @include desktop {
    display: none !important;
  }
  @include tablet {
    display: none !important;
  }
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  align-items: flex-start;
  justify-content: space-around;
  background: #fff;
  z-index: 99999;
  padding: 0 20px;
  a {
    color: #000;
  }
  .logo {
    max-width: 70px;
    //margin-bottom: -50px;
    img {
      width: 70px;
      height: 100px;
    }
    text-align: center;
    overflow: visible;
    z-index: 9999;
  }
  .burger-menu-wrapper,
  .logo,
  .cart {
    width: calc(100% / 3);
    max-width: none;
    height: 40px;
  }
  .burger-menu-wrapper,
  .cart {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .cart {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    .items {
      position: absolute;
      right: 0;
      width: 35px;
      text-align: center;
      top: 12px;
    }
  }
}

section.body {
  margin-top: $headerheight;
}

section.content {
  height: 100vh;
  width: 100%;
  display: flex;
  padding-top: 5vh;
  padding-bottom: 5vh;
  &.center {
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 15vh 0;
  }
  &.hero {
    height: calc(100vh - #{$headerheight});
    padding: 0;
    @include mobile {
      height: auto;
    }
  }
  &.yellow {
    background: $secondary;
    padding: 0;
    .image {
      justify-content: flex-end;
      picture {
        @include tablet {
          width: 70%;
          transform: translateY(50px);
        }
      }
    }
  }
  .image {
    width: 50%;
    height: 100%;
    flex: 1;
    display: flex;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .text {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50%;
    h1,
    h2,
    h3,
    h4,
    p {
      max-width: 590px;
    }
    p {
      margin: 1em 0;
    }
  }
  @include mobile {
    flex-direction: column;
    height: auto;
    .text,
    .image {
      width: 100%;
    }
    .text {
      order: 1;
      padding: 30px;
    }
    .image {
      order: 2;
    }
  }
}

footer {
  background: #d19f4f;
  .content {
    padding: 30px;
    @include mobile {
      padding: 30px 0;
    }
  }

  a {
    color: #000;
  }
  .bar {
    background: #fff;
    padding: 30px;
    .text {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .wrapper {
      width: 100%;

      @include tablet {
        width: 60%;
      }
    }
  }
}

.shopProducts {
  //max-width: 1280px;
  //margin: 0 auto;
}

.shopProduct {
  display: flex;
  margin: 100px auto;
  @include mobile() {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 25px auto;
  }
  .text {
    flex: 1;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include mobile {
      h3 {
        font-weight: 700;
      }
    }
    .stepper {
      display: flex;
      justify-content: space-between;
      @include mobile() {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        min-width: 70vw;
      }
    }
    .react-numeric-input {
      max-width: 200px;
      margin: 0 20px;
      @include mobile() {
        margin: 20px;
        min-width: 70vw;
      }
    }
  }
  @include tablet {
    .text {
      order: 1;
    }
    .image {
      width: 320px;
      order: 2;
    }
    &:nth-child(2n) {
      .text {
        order: 2;
      }
      .image {
        order: 1;
      }
    }
  }
}

.cartProduct {
  display: flex;
  margin: 10px auto;
  @include mobile {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px auto 50px;
    text-align: center;
  }
  .text {
    flex: 1;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include mobile {
      h3 {
        font-weight: 700;
      }
    }
    .stepper {
      display: flex;
      justify-content: space-between;
      input[type="button"] {
        pointer-events: none;
      }
      @include mobile() {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 0px;
      }
    }
    .react-numeric-input {
      max-width: 200px;
      margin-right: 20px;
      @include mobile() {
        margin: 20px;
      }
    }
  }
  @include tablet {
    .text {
      order: 2;
    }
    .image {
      width: 64px;
      order: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:nth-child(2n) {
      .text {
        order: 2;
      }
      .image {
        order: 1;
      }
    }
  }
}

.form {
  .options {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 50px auto;
    align-items: center;
    justify-content: center;

    @include mobile {
      flex-direction: column;
    }
    .option {
      display: flex;
      flex-direction: column;
      padding: 20px;
    }
  }
  .checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    user-select: none;
    cursor: pointer;
    input {
      height: auto;
      margin-right: 10px;
    }
  }
}

.react-numeric-input {
  width: 100%;
  flex: 1;
  border: 2px solid #000;
  input {
    padding: 0 !important;
    width: 100%;
    height: 40px;
  }
  b {
    background: #fff !important;
    border: none !important;
    &:hover {
      i {
        background: #fff !important;
      }
      background: #000 !important;
    }
  }
}

.DateRangePickerInput__withBorder {
  border: 2px solid #000 !important;
  .DateInput {
    input {
      color: #000 !important;
    }
  }
}

.costs {
  margin-bottom: 50px;
}

.form-group {
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
  flex: 1;
  input,
  textarea {
    width: 100%;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    &[type="submit"] {
      background: $secondary;
      color: #000;
      text-align: center;
      &:hover {
        background: #000;
        color: #fff;
      }
    }
  }
}

@keyframes slideSmooth {
  from {
    transform: translate3d(0, 30%, 0);
    visibility: visible;
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.in-view {
  h1,
  h2,
  h3,
  h4,
  p {
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-name: slideSmooth;
  }
}

.datenschutz {
  width: 100%;
  max-width: 740px;
  margin: 10vh auto;
  padding-top: 10vh;
  h1,
  h2,
  h3,
  h4 {
    font-weight: 700;
    margin: 2em 0 1em;
  }
  p {
    margin-bottom: 2em;
  }
}

.cartButton {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .items {
    position: absolute;
    width: 100%;
    text-align: center;
    left: 1px;
    right: 0;
    bottom: 4px;
  }
}

.cartOverview {
  margin: 250px auto 0;
  h1 {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
  @include mobile {
    > section {
      padding: 20px;
    }
    h1 {
      border-bottom: 1px solid #000;
    }
  }
  @include tablet {
    display: flex;
    flex-direction: row;
    > section {
      flex: 1;
      padding: 60px;
      &.address {
        background: #f8f8f8;
        max-width: 600px;
        .form-group {
          width: 100%;
          max-width: none;
          input[type="submit"] {
            max-width: none;
            &:disabled {
              pointer-events: none;
              opacity: 0.4;
            }
          }
        }
        .form {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }
      }
    }
  }
}

.splitButtons {
  display: flex;
  //justify-content: space-between;
  margin: 50px auto;
  @include mobile {
    flex-direction: column;
    align-items: center;
  }
  .splitButton {
    border: 2px solid #000;
    text-align: center;
    color: #000;
    flex: 1;
    max-width: 440px;
    background: #fff;
    appearance: none;
    margin: 0 20px;
    @include mobile {
      margin: 5px 20px;
      font-size: 1.4rem;
      width: 60vw;
    }
    &:hover,
    &.active {
      background: $secondary;
      color: #000;
      cursor: pointer;
    }
  }
}

.rtg-basic-enter {
  opacity: 0;
}
.rtg-basic-enter-active {
  opacity: 1;
  transition: opacity 1000ms;
}
.rtg-basic-exit {
  opacity: 1;
}
.rtg-basic-exit-active {
  opacity: 0;
  transition: opacity 1000ms;
}
